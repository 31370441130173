
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonIcon, alertController } from '@ionic/vue'
import { clipboardOutline } from 'ionicons/icons'
import { mapGetters } from 'vuex'
import { defineComponent } from 'vue'
import storage from '@/zenky/storage'
import build from '@/build'
import ClipboardButton from '@/components/clipboard/ClipboardButton.vue'

export default defineComponent({
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonButton,
    IonIcon,
    ClipboardButton,
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
      employee: 'auth/employee',
    }),

    avatarUrl(): string {
      return this.user.default_avatar_url
    },

    hasUser(): boolean {
      return this.user && this.employee && this.employee.role
    },

    systemInfoText(): string {
      return [
        `Zenky Business v${this.build.version}`,
        `Дата сборки: ${this.build.date}`,
        `Версия API: ${this.build.apiVersion}`,
        `User ID: ${this.hasUser ? this.user.id : 'null'}`,
      ].join(`\n`)
    },
  },

  methods: {
    async signOut() {
      if (!this.hasUser) {
        await this.performLogout()

        return
      }

      const alert = await alertController.create({
        header: 'Выход из аккаунта',
        subHeader: this.employee.store.name,
        message: 'Вы уверены, что хотите выйти из аккаунта?',
        buttons: [
          {
            text: 'Не выходить',
            role: 'cancel',
            handler: () => {
              //
            },
          },
          {
            text: 'Выйти',
            role: 'confirm',
            handler: async () => this.performLogout(),
          },
        ],
      })

      return alert.present()
    },

    async performLogout() {
      await storage.removeOrderStatusFilter()
      await storage.removeOrderStocksFilter()
      await storage.removeStoreId()
      await storage.removeApiToken()

      await this.$router.replace({
        name: 'auth.signin',
        query: {
          loggedOut: '1',
        },
      })
    },
  },

  data() {
    return {
      build,
      icons: {
        clipboardOutline,
      },
    }
  }
})
